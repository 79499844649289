import React from 'react';
import { Grid, Heading, Text } from '@myci/ui-components';
import { Message } from '@myci/intl';
import Bureaus from '@myci/domain-bureaus';

import m from '../messages';
import PublicLayout from '../layouts/PublicLayout';

const Unauthorized = () => (
	<PublicLayout navigation={{ navigationModules: [] }}>
		<Grid row alignItems="center" py={{ xs: 3, md: 5 }}>
			<Grid col={{ md: 6 }}>
				<Heading as="h2" className="h1" mb={{ xs: 3, md: 4 }}>
					<Message {...m.unauthorizedTitle} />
				</Heading>

				<Text mb={0}>
					<Message {...m.unauthorizedPerex} />
				</Text>
			</Grid>
		</Grid>

		<Grid row mb={{ xs: 3, md: 5 }}>
			<Bureaus />
		</Grid>
	</PublicLayout>
);

export default Unauthorized;
